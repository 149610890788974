import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs-custom";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

import { BackHeader } from "./back_header";

import {
  ProjectContainer,
  ProjectTopContainer,
  ProjectTitleStyle,
  ProjectCol,
  ProjectImgCol,
  ProjectRow,
  ProjectRowProcess,
  ProjectNameStyle,
  ProjectContentStyle,
  TagStyle,
  TagContainer,
  TagNameStyle,
  BlobHolder,
  ProjectImgHolder,
  ProjectImg,
  ButtonHolder,
  LeftBlob,
  RightBlob,
  ColoredInternalContainer,
  TextHolder,
  ProjectContentSmallSpaceStyle,
} from "./project_page_styles";

import SEO from "../seo";

import Layout from "../layout";

import YellowBlob from "../../images/yellowblob.svg";
import PinkBlob from "../../images/pinkblob.svg";
import { Blob } from "../index/blob";

import BottomFernOutline from "../../images/bfernoutline.svg";
import BottomFernFill from "../../images/bfernfill.svg";
import { InternalContainer, TextContainer } from "../base_components/layout";
import { process } from "ts-invariant";

const ProjectIntro = props => {
  const title = props.prismicData.project_title[0].text;
  const date = props.prismicData.project_date[0].text;
  const header = props.prismicData.project_description_title[0].text;
  const description = props.prismicData.project_description;
  const img = props.prismicData.project_intro_img;
  const isRight = false;
  return (
    <ProjectTopContainer id="projects">
      <InternalContainer>
        <ProjectTitleStyle style={{ textAlign: `center` }}>
          {title}
        </ProjectTitleStyle>
        <ProjectNameStyle style={{ textAlign: `center` }}>
          {date}
        </ProjectNameStyle>
        <ProjectRowProcess isRight={isRight}>
          <ProjectImgCol isRight={isRight} size={2} breakSize="xl">
            <ProjectImgHolder>
              <ProjectImg src={img.url} alt={img.alt} />
            </ProjectImgHolder>
            <LeftBlob>
              <Parallax x={[-15, 15]}>
                <PinkBlob />
              </Parallax>
            </LeftBlob>
            <RightBlob amount={20}>
              <Parallax x={[15, -15]}>
                <YellowBlob />
              </Parallax>
            </RightBlob>
          </ProjectImgCol>

          <ProjectCol size={3} breakSize="xl">
            <TextContainer isColumn={true}>
              <ProjectNameStyle>{header}</ProjectNameStyle>
              <RichText
                richText={description}
                paragraph={ProjectContentSmallSpaceStyle}
              />
            </TextContainer>
          </ProjectCol>
        </ProjectRowProcess>
      </InternalContainer>
    </ProjectTopContainer>
  );
};

const ProjectProcess = props => {
  const isRight = props.isRight || false;
  return (
    <ProjectContainer>
      <ColoredInternalContainer>
        <ProjectRowProcess isRight={isRight}>
          <ProjectImgCol isRight={isRight} size={2} breakSize="xl">
            <ProjectImgHolder>
              <ProjectImg src={props.img.url} alt={props.img.alt} />
            </ProjectImgHolder>
            <BlobHolder isRight={isRight}>
              <Blob />
            </BlobHolder>
          </ProjectImgCol>

          <ProjectCol size={3} breakSize="xl">
            <TextContainer isColumn={true}>
              <ProjectNameStyle>{props.title}</ProjectNameStyle>
              <RichText
                richText={props.description}
                paragraph={ProjectContentSmallSpaceStyle}
              />
            </TextContainer>
          </ProjectCol>
        </ProjectRowProcess>
      </ColoredInternalContainer>
    </ProjectContainer>
  );
};

const ProjectWhiteBlob = props => {
  const isRight = props.isRight || false;
  return (
    <ProjectContainer id="projects">
      <InternalContainer>
        <ProjectRowProcess isRight={isRight}>
          <ProjectImgCol isRight={isRight} size={2} breakSize="xl">
            <ProjectImgHolder>
              <ProjectImg src={props.img.url} alt={props.img.alt} />
            </ProjectImgHolder>
            <LeftBlob amount={10}>
              <Parallax x={[-15, 15]}>
                <YellowBlob />
              </Parallax>
            </LeftBlob>
            <RightBlob amount={10}>
              <Parallax x={[15, -15]}>
                <PinkBlob />
              </Parallax>
            </RightBlob>
          </ProjectImgCol>

          <ProjectCol size={3} breakSize="xl">
            <TextContainer isColumn={true}>
              <ProjectNameStyle>{props.title}</ProjectNameStyle>
              <RichText
                richText={props.description}
                paragraph={ProjectContentSmallSpaceStyle}
              />
            </TextContainer>
          </ProjectCol>
        </ProjectRowProcess>
      </InternalContainer>
    </ProjectContainer>
  );
};

const ProjectPage = ({ data }) => {
  if (data.prismic.allProjectinfops.edges[0] == null) {
    return <div>empty</div>;
  }

  const prismicData = data.prismic.allProjectinfops.edges[0].node;

  const processTitle = prismicData.process_title[0].text;
  const processDescription = prismicData.process_description;
  const processImg = prismicData.process_img;

  const brandingTitle = prismicData.branding_titlee[0].text;
  const brandingDescription = prismicData.branding_description;
  const brandingImg = prismicData.branding_img;

  const finalTitle = prismicData.final_product_title[0].text;
  const finalDescription = prismicData.final_product_description;
  const finalImg = prismicData.final_product_img;

  const devTitle = prismicData.development_title[0].text;
  const devDescription = prismicData.development_description;
  const devImg = prismicData.development_img;
  return (
    <ParallaxProvider>
      <Layout>
        <SEO title="Projects" />
        <BackHeader />
        <ProjectIntro prismicData={prismicData} />
        <ProjectProcess
          title={processTitle}
          description={processDescription}
          img={processImg}
          isRight={true}
        />
        <ProjectWhiteBlob
          title={brandingTitle}
          description={brandingDescription}
          img={brandingImg}
        />
        <ProjectProcess
          isRight={true}
          title={devTitle}
          description={devDescription}
          img={devImg}
        />
        <ProjectWhiteBlob
          title={finalTitle}
          description={finalDescription}
          img={finalImg}
        />
      </Layout>
    </ParallaxProvider>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query ProjectPages($uid: String) {
        prismic {
          allProjectinfops(uid: $uid) {
            edges {
              node {
                project_title
                branding_description
                branding_img
                branding_titlee
                project_date
                project_description
                project_description_title
                project_intro_img
                development_description
                development_img
                development_title
                final_product_description
                final_product_img
                final_product_title
                process_description
                process_img
                process_title
              }
            }
          }
        }
      }
    `}
    render={data => <ProjectPage data={data} {...props} />}
  />
);
