import styled from "styled-components";
import { sizes } from "../base_components/screenSizes";
import { Link } from "gatsby";

import { colorMap } from "../base_components/colors";
import { Typography } from "../base_components/typography";

export const LinkWrapper = styled(Link)`
  transition: margin 700ms;

  :hover{
    margin-left: -10px;
  }
`;

export const BackHeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  z-index: 99999;
  background: ${colorMap.brandBlue};
  height: 50px;
  display: flex;
  justify-content: start;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0px 80px;

  @media (min-width: 1440px) {
    left: 50%;
    margin-left: -720px;
  }

  @media (max-width: ${sizes.large}px) {
    padding: 0px 20px;
  }

  @media (max-width: ${sizes.medium}px) {
    padding: 0px 20px;
  }
`;

export const MadeWithLove = styled(Typography.body)`
  color: ${colorMap.brandPurple};
`;
