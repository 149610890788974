import React from "react";

import { BackHeaderContainer, MadeWithLove, LinkWrapper } from "./back_header_styles";

export const BackHeader = props => {
  return (
    <BackHeaderContainer>
      <LinkWrapper to="/#projects">
        <MadeWithLove>&#8592; go back</MadeWithLove>
      </LinkWrapper>
    </BackHeaderContainer>
  );
};
