import styled from "styled-components";

import {
  Col,
  Row,
  SectionContainer,
  InternalContainer,
} from "../base_components/layout";
import { sizes } from "../base_components/screenSizes";
import { colorMap } from "../base_components/colors";
import { Typography } from "../base_components/typography";
import {
  ImageBackgroundWrapper,
  ImageWrapper,
} from "../base_components/images";

export const ColoredInternalContainer = styled(InternalContainer)`
  background: ${colorMap.brandBlue};
`;

export const TextHolder = styled.div`
  margin-top: 50px;
  margin-right: 0px;
  margin-left: 0px;
`;
export const ProjectContainer = styled(SectionContainer)`
  background: ${colorMap.white};
  overflow: hidden;
  @media (max-width: ${sizes.small}px) {
    text-align: left;
  }
`;

export const ProjectTopContainer = styled(ProjectContainer)`
  padding-top: 100px;
`;

export const ProjectTitleStyle = styled(Typography.H1)`
  color: ${colorMap.fontLightBlue};
`;

export const ProjectNameStyle = styled(Typography.H2)`
  color: ${colorMap.brandPurple};
`;

export const ProjectContentStyle = styled(Typography.body)`
  color: ${colorMap.fontBlue};
  margin-top: 30px;
  margin-bottom: 15px;
`;

export const ProjectContentSmallSpaceStyle = styled(Typography.body)`
  color: ${colorMap.fontBlue};
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const ProjectCol = styled(Col)`
  @media (max-width: ${sizes.large}px) {
    background: rgba(255, 255, 255, 0.6);
    margin-top: 20px;
    padding: 20px;
    border-radius: 5px;
  }
`;

export const ProjectImgCol = styled(Col)`
  display: flex;
  justify-content: center;
  padding-right: ${props => (props.isRight ? "80px" : "initial")};
  padding-left: ${props => (props.isRight ? "initial" : "80px")};

  @media (max-width: ${sizes.medium}px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const ProjectRowProcess = styled(Row)`
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: ${props => (props.isRight ? "row-reverse" : "initial")};
  margin-top: 0px;
  padding: 60px 20px;

  @media (max-width: ${sizes.medium}px) {
    flex-wrap: "wrap-reverse" !important;
    margin-top: 0px;
    padding: 60px 20px;
  }
`;

export const ProjectRow = styled(Row)`
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: ${props => (props.isColumn ? "column" : "row")};
  margin-top: 0px;

  @media (max-width: ${sizes.medium}px) {
    flex-wrap: wrap-reverse;
    margin-top: 0px;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TagNameStyle = styled(Typography.SH1)`
  color: ${colorMap.brandPurple};
`;

export const TagStyle = styled.div`
  color: ${colorMap.brandPurple};
  border-radius: 5px;
  border: 3px solid ${colorMap.brandPurple};
  width: fit-content;
  padding: 8px;
  margin-right: 12px;
  margin-top: 12px;
`;

export const ButtonHolder = styled.div`
  width: fit-content;
  @media (max-width: ${sizes.medium}px) {
    display: flex;
    justify-content: center;
    width: auto;
  }
`;
export const BlobHolder = styled.div`
  left: ${props => (props.isRight ? "initial" : "-20%")};
  right: ${props => (props.isRight ? "-20%" : "initial")};
  position: absolute;
  height: 700px;
  width: 800px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: all 700ms;

  @media (min-width: ${sizes.xl}px) {
    left: ${props => (props.isRight ? "initial" : "-40%")};
    right: ${props => (props.isRight ? "-40%" : "initial")};
  }

  @media (max-width: ${sizes.medium}px) {
    width: 600px;
    top: 10%;
  }

  @media (max-width: ${sizes.small}px) {
    width: 450px;
    top: 20%;
  }
`;

export const ProjectImgHolder = styled(ImageBackgroundWrapper)`
  width: 100%;
  max-height: 600px;
  z-index: 11;

  @media (max-width: ${sizes.medium}px) {
    max-height: 400px;
  }
  @media (max-width: ${sizes.small}px) {
    max-height: 350px;
  }
`;

export const ProjectImg = styled(ImageWrapper)`
  max-height: 550px;
  object-fit: contain;

  @media (max-width: ${sizes.medium}px) {
    max-height: 350px;
  }
  @media (max-width: ${sizes.small}px) {
    max-height: 300px;
  }
`;

export const LeftBlob = styled.div`
  position: absolute;
  top: -10%;
  left: ${props => (props.amount ? `-${props.amount}%` : "-30%")};
  width: 500px;
  transition: all 700ms;

  @media (min-width: ${sizes.xl}px) {
    width: 800px;
  }

  @media (max-width: ${sizes.medium}px) {
    width: 400px;
  }

  @media (max-width: ${sizes.small}px) {
    width: 350px;
  }
`;

export const RightBlob = styled.div`
  position: absolute;
  bottom: -10%;
  right: ${props => (props.amount ? `-${props.amount}%` : "-0%")};
  width: 500px;
  transition: all 700ms;

  @media (min-width: ${sizes.xl}px) {
    width: 800px;
  }

  @media (max-width: ${sizes.medium}px) {
    width: 400px;
  }

  @media (max-width: ${sizes.small}px) {
    width: 350px;
  }
`;

export const TopFernContainer = styled.div`
  position: absolute;
  top: -20%;
  right: -10%;
  width: 800px;
  transition: all 700ms;
  transform: rotate(180deg);

  @media (max-width: ${sizes.medium}px) {
    width: 500px;
    top: -5%;
    right: -15%;
  }

  @media (max-width: ${sizes.small}px) {
    width: 350px;
    top: 0;
    right: 0;
  }
`;

export const BottomFernContainer = styled.div`
  position: absolute;
  bottom: -10%;
  left: -10%;
  z-index: -1;
  width: 800px;
  transition: all 700ms;
  fill: #a4c7df;

  @media (max-width: 980px) {
    bottom: -30%;
  }

  @media (max-width: ${sizes.medium}px) {
    width: 500px;
    left: -10%;
    opacity: 0;
  }

  @media (max-width: ${sizes.small}px) {
    width: 350px;
    top: -15%;
    left: -10%;
    opacity: 0;
  }
`;
